.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.productManageForm {
}

    .productManageForm .MuiPaper-root .MuiGrid-container {
        padding-bottom: 12px;
    }

        .productManageForm .MuiPaper-root .MuiGrid-container .MuiGrid-item {
            padding: 12px 12px 0px 12px;
        }

            .productManageForm .MuiPaper-root .MuiGrid-container .MuiGrid-item:last-of-type {
                /* margin-bottom: 12px; */
            }

main .MuiPaper-root .MuiPaper-root > .MuiToolbar-root:first-of-type {
    /*display: none !important;*/
}

.image-container {
    position: relative;
    display: inline-block;
}

.image {
    max-width: 100%;
    height: auto;
    display: block;
}


.service-price-text-over {
    position: absolute;
    top: 70%;
    left: 56%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
}

/* Hide header and cancel/ok buttons on appointment date picker*/
.MuiTypography-root.MuiTypography-overline,
.MuiPickersToolbar-root.MuiPickersLayout-toolbar,
.MuiDialogActions-root.MuiDialogActions-spacing.MuiPickersLayout-actionBar {
    display: none;
}

/*.MuiDateCalendar-root {
    font-size: '4em !important';
}*/

/* Adjusting the height of internal elements in DatePicker */
.dateOfBirthPicker .MuiInputBase-root,
.dateOfBirthPicker .MuiInputBase-input,
.dateOfBirthPicker .MuiIconButton-root {
  height: 45px;
  border-radius: 15px;
  width: 100%!important;
}

.dateOfBirthPicker.error .MuiFormHelperText-root{
  color: #D32F2F; 
}

.dateOfBirthPicker.error .MuiInputLabel-root {
  color: #D32F2F; 
}

.dateOfBirthPicker.error .MuiIconButton-root { 
  color: #D32F2F!important; 
}

.dateOfBirthPicker.error.MuiOutlinedInput-notchedOutlineError {
  border-color: #D32F2F!important;
}


.MuiFormControl-root.MuiTextField-root.dateOfBirthPicker.error .MuiOutlinedInput-root fieldset {
  border-color: #D32F2F!important;
}


.requiredText {
  color: #ff0000;
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  padding-top: 10px;
  padding-left:16px;
}

.MuiFormControl-root.MuiTextField-root.dateOfBirthPicker {
    width: 98.8% !important;
    height: 42px;
    border: 2px solid #9CA7B2; 
    font-family: Poppins;
    border-radius: 15px; 
    box-shadow: 0px 5px 10px #0000001A;
}

.MuiFormControl-root.MuiTextField-root.dateOfBirthPicker fieldset {
    border: none;
}

.dateOfBirthPicker.error .MuiFormHelperText-root {
    color: #ff0000 !important;
    font-size: 16px;
    font-family: Poppins !important;
    font-weight: 500;
    letter-spacing: 0.00938em;

}

.appointmentDetailsInputField .MuiInputBase-formControl {
    height: 40px !important;
    border-radius: 15px;
    margin-top: 5px;
}

.autocomplete {
    height: 40px !important;
    border-radius: 15px;
}

body {
    font-family: Poppins, sans-serif;
    font-size: 1.1em !important;
}

.alignTextBottom {
    align-self: flex-end;
}

.termsAndConditions {
    color: #45bdcd;
    text-decoration: none;
}


.MuiStepIcon-root {
    font-size: 2em !important;
    font-weight: bold;
}

.MuiStepLabel-label {
    color: #45bdcd !important;
    font-weight: bold !important;
    font-size: 1em !important;
}

.MuiStepIcon-text {
    fill: white !important;
}

.MuiStepConnector-root {
    padding-top: 6px;
}

.MuiStepConnector-horizontal {
    color: #45bdcd !important;
}

.react-tel-input {
    height: 40px;
    margin-top: 5px;
}

    .flag-dropdown,
    .flag-dropdown .open,
    .selected-flag:hover {
        border-bottom-left-radius: 15px !important;
        border-top-left-radius: 15px !important;
        border: 2px solid #9CA7B2 !important;
        height: 47px;
    }

.flag-dropdownError {
    border-bottom-left-radius: 15px !important;
    border-top-left-radius: 15px !important;
    border: 2px solid #FF0000;
}


.mobilePhoneNoInput {
    border-radius: 15px !important;
    height: 47px;
    width: 100% !important;
}



.mobilePhoneNoInputError {
    border-radius: 15px !important;
    height: 40px !important;
    width: 100% !important;
    border-color: #ff0000 !important;
    color: #ff0000 !important;
    font-size: 16px;
    font-family: Poppins, sans-serif;
    padding-top: 6px;
}

.react-tel-input .form-control {
    height: 47px !important;
}

.react-tel-input .flag-dropdown.invalid-number {
    background-color: white !important;
    border-left: 2px solid red !important;
    border-top: 2px solid red !important;
    border-bottom: 2px solid red !important;
    border-right: none !important;
    box-shadow: 0px 5px 10px #0000001A;
}
.flag-dropdown .open, .selected-flag:hover {
    border: none !important;
}
.react-tel-input .form-control.invalid-number {
    background-color: white !important;
    border: 2px solid red !important;
    box-shadow: 0px 5px 10px #0000001A;
}

@media only screen and (max-width: 600px) {
    .react-tel-input .form-control {
        height: 32px !important;
    }

    .mobilePhoneNoInput {
        border-radius: 10px !important;
        height: 32px !important;
    }

    .flag-dropdown,
    .flag-dropdown .open,
    .selected-flag:hover {
        border-bottom-left-radius: 10px !important;
        border-top-left-radius: 10px !important;
        border: 1px solid #9CA7B2 !important;
        height: 32px;
    }

    .requiredText {
        color: #ff0000;
        font-size: 10px;
        font-weight: 500;
        font-family: Poppins, sans-serif;
        padding-top: 0px;
        margin-top: -8px;
        text-align: right;
        padding-right: 13px;
    }

    .react-tel-input .form-control.invalid-number {
        background-color: white !important;
        border: 1px solid red !important;
        box-shadow: 0px 5px 10px #0000000A;
    }

    .react-tel-input .flag-dropdown.invalid-number {
        background-color: white !important;
        border-left: 1px solid red !important;
        border-top: 1px solid red !important;
        border-bottom: 1px solid red !important;
        border-right: none !important;
        box-shadow: 0px 5px 10px #0000000A;
    }

    .MuiFormControl-root.MuiTextField-root.dateOfBirthPicker {
        width: 98.8% !important;
        height: 29px !important;
        font-family: Poppins;
        box-shadow: 0px 5px 10px #0000001A;
        border-radius: 10px;
        box-shadow: 0px 5px 10px #0000000A;
        border: 1.5px solid #9CA7B2;
        line-height: 0.9em !important;
        font-size: 5px !important;
        padding-bottom: -20px;
    }

    .dateOfBirthPicker .MuiInputBase-root {
        height: 32px;
        font-size: 12px;
        font-family: Poppins, sans-serif;
    }

    .dateOfBirthPicker.error .MuiFormHelperText-root {
        color: #ff0000 !important;
        font-size: 10px;
        font-family: Poppins !important;
        font-weight: 500;
        letter-spacing: 0.00938em;
        text-align: right;
    }
}

.MuiFormControlLabel-asterisk {
    display: none;
}



.MuiFormControl-root.MuiTextField-root.discountCodeInvalid .MuiOutlinedInput-root fieldset {
  border-color: #D32F2F!important;
}
  
/*payment-element*/
#payment-form form {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
}


#payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
}

#payment-element {
    margin-bottom: 24px;
}

/* Buttons and links */
#payment-form button {
    background: #45bdcd;
    font-family: Poppins, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
}

    #payment-form button:hover {
        filter: contrast(115%);
    }

    #payment-form button:disabled {
        opacity: 0.5;
        cursor: default;
    }

/* spinner/processing state, errors */
#payment-form .spinner,
.spinner:before,
.spinner:after {
    border-radius: 50%;
}

#payment-form .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

    #payment-form .spinner:before,
    .spinner:after {
        position: absolute;
        content: '';
    }

    #payment-form .spinner:before {
        width: 10.4px;
        height: 20.4px;
        background: #5469d4;
        border-radius: 20.4px 0 0 20.4px;
        top: -0.2px;
        left: -0.2px;
        -webkit-transform-origin: 10.4px 10.2px;
        transform-origin: 10.4px 10.2px;
        -webkit-animation: loading 2s infinite ease 1.5s;
        animation: loading 2s infinite ease 1.5s;
    }

    #payment-form .spinner:after {
        width: 10.4px;
        height: 10.2px;
        background: #5469d4;
        border-radius: 0 10.2px 10.2px 0;
        top: -0.1px;
        left: 10.2px;
        -webkit-transform-origin: 0px 10.2px;
        transform-origin: 0px 10.2px;
        -webkit-animation: loading 2s infinite ease;
        animation: loading 2s infinite ease;
    }

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 600px) {
    #payment-form form {
        width: 80vw;
        min-width: initial;
    }
}

/*Multichip*/
/*.MuiChip-root {
    height: 150px !important;
}*/


/*Trustpilot widger */
.trustpilot-widget {
    width: 100%;
    height: auto;
}